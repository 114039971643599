import {Axios} from "@/utils/axios";
import {Discount} from "@/models/discount";


export class DiscountServiceClass {

	public url: string = "https://booking-api.webcode.be/api"

	async get() {
		try {
			let response = await Axios.get(this.url + "/discounts");
			if (response.status == 200) {
				return response.data.data.map((a:any) => new Discount(a));
			}
		} catch (e) {

		}
	}

	async single(id:number) {
		try {
			let response = await Axios.get(this.url + "/discount/"+id);
			if (response.status == 200) {
				return new Discount(response.data.data);
			}
		} catch (e) {

		}
	}

	async remove(id:number) {
		try {
			let response = await Axios.delete(this.url + "/discounts/"+id);
			if (response.status == 200) {
				//return new Booking(response.data.data)
			}
		} catch (e) {

		}
	}

	async insert(discount:Discount) {
		try {
			let response = await Axios.post(this.url + "/discounts",{discount});
			if (response.status == 200) {
				return new Discount(response.data.data);
			}
		} catch (e) {

		}
	}

	async update(discount:Discount) {
		try {
			let response = await Axios.put(this.url + "/discounts",{discount});
			if (response.status == 200) {
				return new Discount(response.data.data);
			}
		} catch (e) {

		}
	}


}

export const DiscountService = new DiscountServiceClass();