export class Discount {
	ID: number = 0;
	Name: string = "";
	From: Date = new Date();
	Till: Date = new Date();
	Type: number = 1;
	Discount: number = 0;
	Code: string = "";


	public constructor(data?:any) {
		if (!data) return;
		this.ID = data.id;
		this.Name = data.name;
		this.From = data.from;
		this.Till = data.till;
		this.Type = data.type;
		this.Discount = data.discount;
		this.Code = data.code;
	}

	getDiscountTypes() {

		return [
			{
				id: "1",
				name: "Kortingspercentage",
			},
			{
				id: "2",
				name: "Vast kortingsbedrag",
			},
		];

	}

}