<template>
  <v-container fluid>
    <v-toolbar
        color="white"
        flat
    >

      <v-toolbar-title class="grey--text text--darken-4">
        Overzicht kortingscodes

      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn to="discount/new" color="primary">
        Toevoegen
      </v-btn>
    </v-toolbar>
    <v-list >
      <v-list-item link :to="`discount/`+a.ID" v-for="a in discounts" :key="a.ID">
        <v-list-item-content >
          <v-list-item-title>{{ a.Name }} ({{a.Code}})</v-list-item-title>
          <v-list-item-action-text></v-list-item-action-text>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-container>
</template>

<script>

import {Discount} from "@/models/discount";
const {DiscountService} = require("@/services/discount-service");
export default {
  data(){
    return{
      discounts: Array[Discount] = []
    }
  },
  async created() {
    this.discounts = await DiscountService.get(1);

  }
}
</script>

<style scoped>
  .v-list-item:first-child{ border-top: 1px solid rgba(0, 0, 0, 0.12); }
  .v-list-item{ border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
</style>